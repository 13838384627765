body {
  color: #fff;
  background-color: #1e1e2f;
  margin: 0 10px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
}
h1, h2 {
  text-align: center;
  margin: 0.75em;
}
h3 {
  margin-top: 0.25rem;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.mb-2 {
  margin-bottom: .5rem;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.mb-auto {
  margin-bottom: auto;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.brewery-image img {
  width: 150px;
  height: 150px;
}
.placeholder-image {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-45deg);
  height: 150px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #27293d;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
  padding-left: 11rem;
  position: absolute;
}
*, ::after, ::before {
  box-sizing: border-box;
}
.untappd-body {
  padding-left: 110px;
  position: absolute;
}
.untappd-body i {
  padding-left: 5px;
}
.card-text-primary {
  color: #84a7e3;
}
.text-muted {
  color: #6c757d;
}
.text-center {
  text-align: center;
}
span.badge-primary {
  background-color: #84a7e3;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
img {
  width: 100px;
  height: 100px;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
@media only screen and (max-width: 400px) {
  h3 {
      font-size: 1.5em;
  }
  .card-body {
      padding-left: 10rem;
  }

}
@media only screen and (max-width: 1600px) {
h3 {
      font-size: 1.5em;
  }
  .untappd-body {
      font-size: 0.9em;
  }

}
@media (min-width: 1200px) {
  .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
  }
}
@media (min-width: 1200px) {
  .col-xl-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
  }
}
@media (min-width: 576px) {
  .d-sm-inline {
      display: inline;
  }
}